body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebf6fa;
}

/*@media (max-width: 720px) {
  .row {
    flex-direction: column;
  }
  .col {
    width: auto;
  }
}
@media (min-width: 721px) {
  .row {
    flex-direction: row;
  }
  .col {
    width: 300px;
  }
}

.row {
  display: flex;
  justify-content: center;
}

.col { padding: 15px; }


h3 {
  font-size: 3.5em;
  line-height: 1.2
}

p { font-size: 1.2em; }

a {
  color: #fff;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
}

.header {
  background: #234;
  min-height: 300px
}

.main-columns {
  background: rgba(yellow, .7);
}

.react-parallax {
  &__layers {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  
  &__layer {
    position: absolute;
    left: 0;
    width: 100%;
    will-change: transform;
    
    &--static {
      h3, p {
        margin: 0;
        padding: 0
      }
      
      a {
        position: absolute;
        bottom: 2em;
        left: 2em;
        max-width: 62%;
        display: inline-block
      }
    }
  }
}
*/